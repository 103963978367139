import React from 'react'
import './footer.css'
import Github from '../Assets/github.png'
import Instagram from '../Assets/instagram.png'
import Linkdin from '../Assets/linkedin.png'
import Logo from '../Assets/logo.png'

const Footer = () => {
  return (
    <div className='Footer-container'>

    <hr/>
    <div className="footer">
    <div className="social-links">
    <img src={Github} alt="" />
    <img src={Instagram}alt="" />
    <img src={Linkdin} alt="" />
    </div>
    <div className="logo-footer">
    <img src={Logo} alt="" />
    </div>
    </div>
    <div className="blur footer-blur-1"></div>
    <div className="blur footer-blur-2"></div>
    </div>
    
  )
}

export default Footer