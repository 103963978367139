import React, { useRef } from 'react';
import emailjs from '@emailjs/browser'
import './join.css'

const Join = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    console.log(e.target);
        emailjs.sendForm('service_qlj68ek', 'template_rigc6vf', form.current, 'rL6C56C8U5nsgOOhp')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
        };
  return (
    <div className='Join' id='join'>
    <div className="left-j">
    <hr />
    <div>
    <span className='stroke-text'>READY TO</span>
    <span>LEVEL UP</span>
    </div>
    <div>
    <span>YOUR BODY</span>
    <span className='stroke-text'>WITH US?</span>
    </div>
    </div>
    <div className="right-j">
    <form ref={form} action="" className="email-container" onSubmit={sendEmail}>
    <input type="email" name='user-email' placeholder='enter your Email-address' />
    <button className='btn btn-j'>Join-now</button>
    </form>
    </div>
    </div>
  )
}
export default Join

