
import './App.css';
import Hero from './components/Hero';
import Programs from './components/Programs';
import Reasons from './Reasons/Reasons';
import Plans from './Plans/Plans';
import Testimonials from './Testimonials/Testimonials';
import Join from './Join/Join';
import Footer from './footer/Footer';


function App() {
  return (
    <div className="App">
   <Hero>
   </Hero>
    <Programs/>
    <Reasons/>
    <Plans/>
    <Testimonials/>
    <Join/>
    <Footer/>

    </div>
  );
}

export default App;
