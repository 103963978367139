import React from 'react'
import {plansData} from '../components/data/plansData.js'
import whiteTick from '../Assets/whiteTick.png'
import './Plans.css'
const Plans = () => {
  return (
    <div className="plans-container">
    <div className="blur plans-blur-1"></div>
    <div className="blur plans-blur-2"></div>
    <div className="programs-header" style={{gap : '2rem'}}>
    <span className='stroke-text'>READY TO START</span>
    <span>YOUR JOURNEY</span>
    <span>NOW  WITHUS</span>
    
    </div>
    <div className="plans" id='Planss'>
    {plansData.map((plan,i)=>(
        <div className="plan" key={i}>
        {plan.icon}
        <span>{plan.name}</span>
        <span>${plan.price}</span>
        <div className="features">
        {plan.features.map((feature,index)=>(
            <div className="feature" key={index}>
            <img src={whiteTick} alt="" />
            <span>{feature}</span>
            </div>
        ))}
        </div>
        <div>
        <span>see more benefits-</span>
        </div>
        <button>Join-now</button>
        
        </div>
    
    ))}
    </div>
    </div>
    
  )
}

export default Plans