import React from 'react'
import Header from '../Header/Header';
import './Hero.css';
import hero_image from '../Assets/hero_image.png';
import hero_image_back from '../Assets/hero_image_back.png';
import heart from '../Assets/heart.png';
import Calories from '../Assets/calories.png';
import NumberCounter from 'number-counter'
import {motion} from 'framer-motion'
const Hero = () => {
  const transition = {type: 'spring',duration:3}
  const mobile = window.innerWidth<=768?true:false;
  return (
    <div className='hero' id='Hero'>
    <div className="blur hero-blur" ></div>
    <div className='left-h'>
    <Header/>

  {/*Its the add section*/}
    <div className='add'>
    <motion.div 
    initial={{left: mobile? "165px":"238px"}}
    whileInView = {{left: '6px'}}
    transition = {{...transition,type: 'tween'}}
    >
     
    </motion.div>
    <span>We make a promise,to make you fit</span>
    </div>
    
    {/*large heading*/}
    <div className='Heading'>
    <div>
    <span className='stroke-text'>Have </span>
    <span>Your</span>
    </div>
    <div>
    <span> Dream shape</span>
    </div>
    <div>
    <span>Make your body look healthier,Inside and outside</span>
    </div>
    </div>

    {/*figures*/}
    
<div className="figures">
    <div>
    <span><NumberCounter end={140} start={10} delay="100" preFix="+"/></span>
    <span>expert coaches</span>
    </div>
    <div>
    <span><NumberCounter end={140} start={10} delay="100" preFix="+"/></span>
    <span>members joined</span>
    </div>
    <div>
    <span>+50</span>
    <span>fitness programs</span>
    </div>
</div>


  {/* buttons */}
  <div className="hero-Buttons">
  <button className="btn">Get started</button>

  <button className="btn">Learn more</button>
  </div>
    </div>
    <div className='right-h'>
    <button className='btn'>Join Now</button>
    <motion.div
    transition={transition}
    initial = {{right:"-1rem"}}
    whileInView={{right: "4rem"}}

     className="heart-rate">
    <img src={heart} alt=""></img>
    <span>Heart-rate</span>
    <span>116 bpm</span>
    </motion.div>

    {/* hero images */}
    <img src={hero_image} alt="" className="hero-image"></img>
     <img src={hero_image_back} alt="" className="hero-image-back"></img>
{/*calories */}
<motion.div 
className="calories">
<img src={Calories} alt=""></img>
<div>
<span>Calories Burned</span><span>220 Kcal</span>
</div>

</motion.div>
    </div>           
    </div>

  )
}  

export default Hero
